<template>
    <article>
        <section class="newsInfo">
            <div class="cont">
                <div class="colonne">
                    <div class="innerCont colonnaMain">

                        <!-- BODY -->
                        <ModuloBody v-if="news.translations && news.translations[0]?.body" :body="news.translations[0]?.body" />

                        <!-- IMMAGINI -->
                         <ModuloImmagine v-if="news.cover" :image="{ directus_files_id: news.cover }" :width="800" imageClass="cover" />
                    </div>
                </div>
            </div>
        </section>
    </article>
</template>

<script lang="ts" setup>
const { localeProperties } = useI18n()
const setI18nParams = useSetI18nParams()
const directusUrl = useDirectusUrl()
const route = useRoute()
const props = defineProps<{ news: any, routes: any }>()

useHead({
    link: [{ rel: 'canonical', href: `https://turismotorino.org${route.path.replace(/\/$/, "")}` }],
});
useSeoMeta({
    title: () => props.news && props.news.translations && props.news.translations[0]?.og_title ? `${props.news.translations[0].og_title} | Turismo Torino e Provincia` : null,
    ogTitle: () => props.news && props.news.translations && props.news.translations[0]?.og_title ? `${props.news.translations[0].og_title} | Turismo Torino e Provincia` : null,
    twitterTitle: () => props.news && props.news.translations && props.news.translations[0]?.og_title ? `${props.news.translations[0].og_title} | Turismo Torino e Provincia` : null,

    description: () => props.news && props.news.translations && props.news.translations[0]?.og_description ? props.news.translations[0].og_description : null,
    ogDescription: () => props.news && props.news.translations && props.news.translations[0]?.og_description ? props.news.translations[0].og_description : null,
    twitterDescription: () => props.news && props.news.translations && props.news.translations[0]?.og_description ? props.news.translations[0].og_description : null,

    ogImage: () => props.news && props.news.cover && props.news.cover.height && props.news.cover.width ? `${directusUrl}assets/${props.news.cover.id}?width=1600&format=jpeg` : null,
    twitterImage: () => props.news && props.news.cover && props.news.cover.height && props.news.cover.width ? `${directusUrl}assets/${props.news.cover.id}?width=1600&format=jpeg` : null,

    ogLocale: () => localeProperties.value.isold,
})

if (props.routes && route.name.indexOf('preview') != 0) setI18nParams(props.routes)

useJsonld(() => ({
    '@context': 'https://schema.org',
    '@graph': [
        {
            '@type': 'BreadcrumbList',
            itemListElement: [
                {
                    '@type': 'ListItem',
                    position: 1,
                    name: 'Convention Bureau',
                    item: `https://turismotorino.org/convention-bureau`
                },
                {
                    '@type': 'ListItem',
                    position: 2,
                    name: 'News Convention',
                    item: `https://turismotorino.org/convention-bureau/news`
                },
                {
                    '@type': 'ListItem',
                    position: 3,
                    name: props.news?.translations?.[0]?.titolo,
                    item: `https://turismotorino.org${route.path.replace(/\/$/, "")}`
                }
            ]
        },
        {
            "@type": "WebPage",
            "@id": `https://turismotorino.org${route.path.replace(/\/$/, "")}/#webpage`,
            url: `https://turismotorino.org${route.path.replace(/\/$/, "")}`,
            name: props.news && props.news.translations && props.news.translations[0]?.og_title ? props.news.translations[0].og_title : 'Turismo Torino e Provincia',
            isPartOf: {
                "@id": `https://turismotorino.org/${localeProperties.value.code}/#website`,
            },
            primaryImageOfPage: props.news && props.news.cover && props.news.cover.height && props.news.cover.width ? {
                '@type': 'ImageObject',
                '@id': `https://turismotorino.org${route.path.replace(/\/$/, "")}/#primaryimage`,
                url: `${directusUrl}assets/${props.news.cover.id}?width=1600&format=jpeg`,
                width: 1600,
                height: (props.news.cover.height * 1600 / props.news.cover.width).toFixed(0),
                inLanguage: localeProperties.value.isold,
                caption: props.news.cover.title
            } : undefined,
            datePublished: props.news && props.news.date_created ? new Date(props.news.date_created).toJSON() : undefined,
            dateModified: props.news && props.news.date_updated ? new Date(props.news.date_updated).toJSON() : undefined,
            description: props.news && props.news.translations && props.news.translations[0]?.og_description ? props.news.translations[0].og_description : undefined,
            inLanguage: localeProperties.value.isold,
            potentialAction: [
                {
                    "@type": "ReadAction",
                    target: [
                        `https://turismotorino.org${route.path.replace(/\/$/, "")}`
                    ]
                }
            ]
        }
    ]
}))
</script>
<style lang="scss" scoped>
article {
    margin: var(--article-top) 0 var(--article-bottom) 0;
    display: flex;
    flex-direction: column;
    gap: var(--article-gap-outer);

    .newsInfo {
        .colonne {
            display: flex;
            justify-content: space-between;
            gap: var(--margin-double);

            .colonnaMain {
                display: flex;
                flex-direction: column;
                gap: var(--article-gap-outer);
                width: 100%;
            }

            &:deep(.cont) {
                padding: 0;
            }
        }
    }
}

@media screen and (max-width: 1060px) {
    article {
        .newsInfo {
            .colonne {
                flex-direction: column;
            }
        }
    }
}
</style>