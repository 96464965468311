<template>
    <div>
        <!-- PAGINE -->
        <main id="mainContent" v-if="pagina && pagina.pagine && pagina.pagine[0]">
            <WebsiteHero :body="pagina.pagine[0]" :routes="routes" />
            <ContenutoPagina :pagina="pagina.pagine[0]" :routes="routes" />
            <DelayHydration>
                <LazyWebsiteFooter />
            </DelayHydration>
        </main>
        <!-- RISORSE -->
        <main id="mainContent" v-else-if="pagina && pagina.risorse && pagina.risorse[0]">
            <div class="headerSpace"></div>
            <div class="cont breadcrumbPageCont">
                <OggettoBreadcrumbPill :routes="routes" />
            </div>
            <div class="cont">
                <div class="titleBoxInfo" v-if="pagina.risorse[0].translations && pagina.risorse[0].translations[0]?.titolo">
                    <div class="boxText">
                        <h1 class="title2">{{ pagina.risorse[0].translations[0]?.titolo }}</h1>
                        <div class="paragraph" v-if="subinfoRicettivita">{{ subinfoRicettivita }}</div>
                        <OggettoClassificazioneMichelin :stelle="pagina.risorse[0].ristorazione_stelle_michelin" />
                        <OggettoClassificazioneHotel :categoria="pagina.risorse[0].ricettivita_categoria" />
                        <div class="categoria title4" v-if="pagina.risorse[0].tipologia && pagina.risorse[0].tipo != 'ricettivita'">{{ pagina.risorse[0].tipologia.translations?.[0]?.etichetta }}</div>
                        <div class="ricettivita title4" v-else-if="(pagina.risorse[0].ricettivita_tipologia || pagina.risorse[0].ricettivita_edificio || pagina.risorse[0].ricettivita_catena) && pagina.risorse[0].tipo == 'ricettivita'">
                            <div class="categoria" v-if="pagina.risorse[0].ricettivita_tipologia || pagina.risorse[0].ricettivita_catena"><template v-if="pagina.risorse[0].ricettivita_tipologia">{{ $t(pagina.risorse[0].ricettivita_tipologia) }}<template v-if="pagina.risorse[0].ricettivita_catena && pagina.risorse[0].ricettivita_tipologia"> - </template></template><template v-if="pagina.risorse[0].ricettivita_catena">{{ pagina.risorse[0].ricettivita_catena.nome }}</template></div>
                            <div class="edificio" v-if="pagina.risorse[0].ricettivita_edificio && (pagina.risorse[0].ricettivita_tipologia == 'albergo' || pagina.risorse[0].ricettivita_tipologia == 'ostello')">{{pagina.risorse[0].ricettivita_edificio.map((e: string) => $t(e)).join(', ')}}</div>
                        </div>
                    </div>
                    <div class="boxInfo">
                        <div class="title4 codicecir" v-if="pagina.risorse[0].codice_cir">{{ $t('codice_cir') }}: <span class="codice">{{ pagina.risorse[0].codice_cir }}</span></div>
                    </div>
                </div>
            </div>
            <div class="targetRow" v-if="(pagina.risorse[0].accordion_target && pagina.risorse[0].accordion_target.length > 0) || (pagina.risorse[0].accordion_design && pagina.risorse[0].accordion_design.length > 0)">
                <div class="cont">
                    <div class="innerCont">
                        <div class="targets">
                            <template v-for="accordion_target in pagina.risorse[0].accordion_target">
                                <NTag size="large" round v-if="accordion_target?.target?.translations" :color="{ color: '#5AEDE4', textColor: '#1A1B3A', borderColor: '#5AEDE4' }" :strong="true" @click="gotoTarget(accordion_target?.target?.slug)">
                                    {{ accordion_target?.target?.translations[0]?.etichetta }}
                                </NTag>
                            </template>
                            <NTag size="large" round v-if="pagina.risorse[0].accordion_design && pagina.risorse[0].accordion_design.length > 0" :color="{ color: '#e75a5d', textColor: '#1A1B3A', borderColor: '#e75a5d' }" :strong="true" @click="gotoTarget('design')">{{ $t('design') }}</NTag>
                        </div>
                    </div>
                </div>
            </div>
            <ContenutoRisorsa v-if="pagina.risorse[0]" :risorsa="pagina.risorse[0]" :routes="routes" />
            <WebsiteFooter />
        </main>
        <!-- RISORSE CONVENTION -->
        <main id="mainContent" v-else-if="pagina && pagina.risorse_convention && pagina.risorse_convention[0]">
            <div class="headerSpace"></div>
            <div class="cont breadcrumbPageCont">
                <OggettoBreadcrumbPill :routes="routes" />
            </div>
            <div class="cont">
                <div class="titleBoxInfo" v-if="pagina.risorse_convention[0].translations && pagina.risorse_convention[0].translations[0]?.titolo">
                    <div class="boxText">
                        <h1 class="title2">{{ pagina.risorse_convention[0].translations[0]?.titolo }}</h1>
                        <OggettoClassificazioneHotel :categoria="pagina.risorse_convention[0].ricettivita_categoria" />
                        <div class="categoria title4" v-if="pagina.risorse_convention[0].tipologia_mice && pagina.risorse_convention[0].tipo != 'ricettivita'">{{ pagina.risorse_convention[0].tipologia_mice.translations?.[0]?.etichetta }}</div>
                        <div class="categoria title4" v-else-if="pagina.risorse_convention[0].ricettivita_tipologia && pagina.risorse_convention[0].tipo == 'ricettivita'">{{ pagina.risorse_convention[0].ricettivita_tipologia }}</div>
                    </div>
                    <div class="boxInfo">
                        <div class="title4 codicecir" v-if="pagina.risorse_convention[0].codice_cir">{{ $t('codice_cir') }}: <span class="codice">{{ pagina.risorse_convention[0].codice_cir }}</span></div>
                    </div>
                </div>
            </div>
            <ContenutoRisorsaConvention v-if="pagina.risorse_convention[0]" :risorsa="pagina.risorse_convention[0]" :routes="routes" />
            <WebsiteFooter />
        </main>
        <!-- EVENTI -->
        <main id="mainContent" v-else-if="pagina && pagina.eventi && pagina.eventi[0]">
            <div class="headerSpace"></div>
            <div class="cont breadcrumbPageCont">
                <OggettoBreadcrumbPill :routes="routes" />
            </div>
            <div class="cont">
                <div class="titleBox" v-if="pagina.eventi[0].translations && pagina.eventi[0].translations[0]?.titolo">
                    <div v-if="pagina.eventi[0].scaduto">
                        <div class="titleTag concluso" v-if="pagina.eventi[0].scaduto">{{ $t('concluso') }}</div>
                    </div>
                    <h1 class="title2">{{ pagina.eventi[0].translations[0]?.titolo }}</h1>
                    <div class="subinfo">
                        <div class="title4" v-if="dataSottotitolo">{{ dataSottotitolo }}</div>
                        <div class="title4 comune" v-if="pagina.eventi[0].comune">{{ pagina.eventi[0].comune.nome }}</div>
                    </div>
                    <div class="categoria title4" v-if="pagina.eventi[0].categorie && pagina.eventi[0].categorie.length > 0">{{pagina.eventi[0].categorie.map((c: any) => c?.categorie_eventi_id?.translations?.[0]?.etichetta).join(', ')}}</div>
                </div>
            </div>
            <div class="targetRow" v-if="pagina.eventi[0].accordion_target && pagina.eventi[0].accordion_target.length > 0">
                <div class="cont">
                    <div class="innerCont">
                        <div class="targets">
                            <template v-for="accordion_target in pagina.eventi[0].accordion_target">
                                <NTag size="large" round v-if="accordion_target?.target?.translations" :color="{ color: '#5AEDE4', textColor: '#1A1B3A', borderColor: '#5AEDE4' }" :strong="true" @click="gotoTarget(accordion_target?.target?.slug)">
                                    {{ accordion_target?.target?.translations[0]?.etichetta }}
                                </NTag>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <ContenutoEvento v-if="pagina.eventi[0]" :evento="pagina.eventi[0]" :routes="routes" />
            <WebsiteFooter />
        </main>
        <!-- SPETTACOLI -->
        <main id="mainContent" v-else-if="pagina && pagina.spettacoli && pagina.spettacoli[0]">
            <div class="headerSpace"></div>
            <div class="cont breadcrumbPageCont">
                <OggettoBreadcrumbPill :routes="routes" />
            </div>
            <div class="cont">
                <div class="titleBoxButton" v-if="pagina.spettacoli[0].translations && pagina.spettacoli[0].translations[0]?.titolo">
                    <div class="boxText">
                        <div v-if="pagina.spettacoli[0].scaduto">
                            <div class="titleTag concluso" v-if="pagina.spettacoli[0].scaduto">{{ $t('concluso') }}</div>
                        </div>
                        <h1 class="title2">{{ pagina.spettacoli[0].translations[0]?.titolo }}</h1>
                        <div class="subinfo">
                            <div class="title4" v-if="pagina.spettacoli[0].date_spettacoli">{{ verbalizzaSottotitoloSpettacolo(pagina.spettacoli[0].date_spettacoli) }}</div>
                            <div class="title4 comune" v-if="pagina.spettacoli[0].luogo && pagina.spettacoli[0].luogo.comune">{{ pagina.spettacoli[0].luogo.comune.nome }}</div>
                        </div>
                        <div class="categoria title4" v-if="pagina.spettacoli[0].categoria">{{ pagina.spettacoli[0].categoria.translations?.[0]?.etichetta }}</div>
                    </div>
                    <div class="boxButton">
                        <!--<OggettoPrenotaSpettacolo :spettacolo="pagina.spettacoli[0]" />-->
                    </div>
                </div>
            </div>
            <ContenutoSpettacolo v-if="pagina.spettacoli[0]" :spettacolo="pagina.spettacoli[0]" :routes="routes" />
            <WebsiteFooter />
        </main>
        <!-- VISITE GUIDATE -->
        <main id="mainContent" v-else-if="pagina && pagina.visite_guidate && pagina.visite_guidate[0]">
            <div class="headerSpace"></div>
            <div class="cont breadcrumbPageCont">
                <OggettoBreadcrumbPill :routes="routes" />
            </div>
            <div class="cont">
                <div class="titleBoxButton" v-if="pagina.visite_guidate[0].translations && pagina.visite_guidate[0].translations[0]?.titolo">
                    <div class="boxText">
                        <div v-if="pagina.visite_guidate[0].scaduto">
                            <div class="titleTag concluso" v-if="pagina.visite_guidate[0].scaduto">{{ $t('concluso') }}</div>
                        </div>
                        <h1 class="title2">{{ pagina.visite_guidate[0].translations[0]?.titolo }}</h1>
                        <div class="subinfo">
                            <div class="title4" v-if="pagina.visite_guidate[0].date_visite_guidate">{{ verbalizzaSottotitoloVisitaGuidata(pagina.visite_guidate[0].date_visite_guidate) }}</div>
                            <div class="title4 comune" v-if="pagina.visite_guidate[0].comune">{{ pagina.visite_guidate[0].comune.nome }}</div>
                        </div>

                    </div>
                    <div class="boxButton">
                        <OggettoPrenotaVisitaGuidata :visita="pagina.visite_guidate[0]" />
                    </div>
                </div>
            </div>
            <ContenutoVisitaGuidata v-if="pagina.visite_guidate[0]" :visita="pagina.visite_guidate[0]" :routes="routes" />
            <WebsiteFooter />
        </main>
        <!-- PRODOTTI -->
        <main id="mainContent" v-else-if="pagina && pagina.prodotti && pagina.prodotti[0]">
            <div class="headerSpace"></div>
            <div class="cont breadcrumbPageCont">
                <OggettoBreadcrumbPill :routes="routes" />
            </div>
            <div class="cont">
                <div class="titleBoxButton" v-if="pagina.prodotti[0].translations && pagina.prodotti[0].translations[0]?.titolo">
                    <div class="boxText">
                        <h1 class="title2">{{ pagina.prodotti[0].translations[0]?.titolo?.replace(/\+/g, '+&#8203;') }}</h1>
                        <div class="subinfo" v-if="pagina.prodotti[0].translations && pagina.prodotti[0].translations[0]?.validita">
                            <div class="title4">{{ pagina.prodotti[0].translations[0]?.validita }}</div>
                        </div>
                        <div class="title4 categoria" v-if="pagina.prodotti[0].tipologia">{{ $t(`prodotto_${pagina.prodotti[0].tipologia}`) }}</div>
                    </div>
                    <div class="boxButton" v-if="pagina.prodotti[0].tipologia_acquistabile && pagina.prodotti[0].tipologia_cta">
                        <OggettoAcquistaProdotto :prodotto="pagina.prodotti[0]" />
                    </div>
                </div>
            </div>
            <ContenutoProdotto v-if="pagina.prodotti[0]" :prodotto="pagina.prodotti[0]" :routes="routes" />
            <WebsiteFooter />
        </main>
        <!-- ATTIVITÀ -->
        <main id="mainContent" v-else-if="pagina && pagina.attivita && pagina.attivita[0]">
            <div class="headerSpace"></div>
            <div class="cont breadcrumbPageCont">
                <OggettoBreadcrumbPill :routes="routes" />
            </div>
            <div class="cont">
                <div class="titleBox" v-if="pagina.attivita[0].translations && pagina.attivita[0].translations[0]?.titolo">
                    <div v-if="pagina.attivita[0].scaduto">
                        <div class="titleTag concluso" v-if="pagina.attivita[0].scaduto">{{ $t('concluso') }}</div>
                    </div>
                    <h1 class="title2">{{ pagina.attivita[0].translations[0]?.titolo }}</h1>
                    <div class="subinfo">
                        <div class="title4" v-if="dataSottotitolo">{{ dataSottotitolo }}</div>
                        <div class="title4 comune" v-if="pagina.attivita[0].comune_partenza">{{ pagina.attivita[0].comune_partenza.nome }}</div>
                        <div class="title4 comune" v-else-if="pagina.attivita[0].comune_suggerito">{{ pagina.attivita[0].comune_suggerito }}</div>
                    </div>
                    <div class="categoria title4" v-if="pagina.attivita[0].tipologia">{{ pagina.attivita[0].tipologia.translations?.[0]?.etichetta }}</div>
                </div>
            </div>
            <div class="targetRow" v-if="pagina.attivita[0].accordion_target && pagina.attivita[0].accordion_target.length > 0">
                <div class="cont">
                    <div class="innerCont">
                        <div class="targets">
                            <template v-for="accordion_target in pagina.attivita[0].accordion_target">
                                <NTag size="large" round v-if="accordion_target?.target?.translations" :color="{ color: '#5AEDE4', textColor: '#1A1B3A', borderColor: '#5AEDE4' }" :strong="true" @click="gotoTarget(accordion_target?.target?.slug)">
                                    {{ accordion_target?.target?.translations[0]?.etichetta }}
                                </NTag>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <ContenutoAttivita v-if="pagina.attivita[0]" :attivita="pagina.attivita[0]" />
            <WebsiteFooter />
        </main>
        <!-- PACCHETTI -->
        <main id="mainContent" v-else-if="pagina && pagina.pacchetti && pagina.pacchetti[0]">
            <div class="headerSpace"></div>
            <div class="cont breadcrumbPageCont">
                <OggettoBreadcrumbPill :routes="routes" />
            </div>
            <div class="cont">
                <div class="titleBox" v-if="pagina.pacchetti[0].translations && pagina.pacchetti[0].translations[0]?.titolo">
                    <div v-if="pagina.pacchetti[0].scaduto">
                        <div class="titleTag concluso" v-if="pagina.pacchetti[0].scaduto">{{ $t('concluso') }}</div>
                    </div>
                    <h1 class="title2">{{ pagina.pacchetti[0].translations[0]?.titolo }}</h1>
                    <div class="subinfo">
                        <div class="title4" v-if="dataSottotitolo">{{ dataSottotitolo }}</div>
                        <div class="title4 comune" v-if="pagina.pacchetti[0].comune">{{ pagina.pacchetti[0].comune.nome }}</div>
                        <div class="title4 comune" v-else-if="pagina.pacchetti[0].comune_suggerito">{{ pagina.pacchetti[0].comune_suggerito }}</div>
                    </div>
                    <div>
                        <div class="categoria title4" v-if="pagina.pacchetti[0].tipologia">{{ $t(pagina.pacchetti[0].tipologia) }}</div>
                        <div class="subcategorie title4" v-if="pagina.pacchetti[0].categorie && pagina.pacchetti[0].categorie.length > 0">{{pagina.pacchetti[0].categorie.map((c: any) => c?.categorie_eventi_id?.translations?.[0]?.etichetta).join(', ')}}</div>
                    </div>
                </div>
            </div>
            <div class="targetRow" v-if="pagina.pacchetti[0].accordion_target && pagina.pacchetti[0].accordion_target.length > 0">
                <div class="cont">
                    <div class="innerCont">
                        <div class="targets">
                            <template v-for="accordion_target in pagina.pacchetti[0].accordion_target">
                                <NTag size="large" round v-if="accordion_target?.target?.translations" :color="{ color: '#5AEDE4', textColor: '#1A1B3A', borderColor: '#5AEDE4' }" :strong="true" @click="gotoTarget(accordion_target?.target?.slug)">
                                    {{ accordion_target?.target?.translations[0]?.etichetta }}
                                </NTag>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <ContenutoPacchetto v-if="pagina.pacchetti[0]" :pacchetto="pagina.pacchetti[0]" />
            <WebsiteFooter />
        </main>
        <!-- OPERATORE OUTDOOR -->
        <main id="mainContent" v-else-if="pagina && pagina.users && pagina.users[0] && pagina.users[0].pagina_operatore_outdoor">
            <div class="headerSpace"></div>
            <div class="cont breadcrumbPageCont">
                <OggettoBreadcrumbPill :routes="routes" />
            </div>
            <div class="cont">
                <div class="titleBox" v-if="nomeOperatore">
                    <h1 class="title2">{{ nomeOperatore }}</h1>
                    <div class="categoria title4" v-if="pagina.users[0].categoria_operatore_outdoor">{{ $t(pagina.users[0].categoria_operatore_outdoor) }}</div>
                </div>
            </div>
            <ContenutoOperatoreOutdoor v-if="pagina.users[0]" :operatore="pagina.users[0]" :routes="routes" />
            <WebsiteFooter />
        </main>
        <!-- OPERATORE INCOMING -->
        <main id="mainContent" v-else-if="pagina && pagina.users && pagina.users[0] && pagina.users[0].pagina_operatore_incoming">
            <div class="headerSpace"></div>
            <div class="cont breadcrumbPageCont">
                <OggettoBreadcrumbPill :routes="routes" />
            </div>
            <div class="cont">
                <div class="titleBox" v-if="pagina.users[0].denominazione">
                    <h1 class="title2">{{ pagina.users[0].denominazione }}</h1>
                    <div class="categoria title4" v-if="pagina.users[0].categoria_operatore_incoming">{{ $t(pagina.users[0].categoria_operatore_incoming) }}</div>
                </div>
            </div>
            <ContenutoOperatoreIncoming v-if="pagina.users[0]" :operatore="pagina.users[0]" :routes />
            <WebsiteFooter />
        </main>
        <!-- NEWS CONVENTION -->
        <main id="mainContent" v-else-if="pagina && pagina.news_convention && pagina.news_convention[0]">
            <div class="headerSpace"></div>
            <div class="cont breadcrumbPageCont">
                <OggettoBreadcrumbPill :routes="routes" />
            </div>
            <div class="cont">
                <div class="titleBox" v-if="pagina.news_convention[0].translations && pagina.news_convention[0].translations[0]?.titolo">
                    <h1 class="title2">{{ pagina.news_convention[0].translations[0]?.titolo }}</h1>
                    <div class="data title4">{{ dataCompletaSenzaOrario(pagina.news_convention[0].data_pubblicazione) }}</div>
                </div>
            </div>
            <ContenutoNewsConvention v-if="pagina.news_convention[0]" :news="pagina.news_convention[0]" :routes="routes" />
            <WebsiteFooter />
        </main>
    </div>
</template>

<script lang="ts" setup>
import { NTag } from 'naive-ui'
import type { Orario } from '~/utils/schema'

const { verbalizzaSottotitolo, verbalizzaSottotitoloSpettacolo, verbalizzaSottotitoloVisitaGuidata, dataCompletaSenzaOrario } = useOrari()
const { createEventiRoutes, createPagineRoutes, createProdottiRoutes, createRisorseRoutes, createRisorseConventionRoutes, createSpettacoliRoutes, createVisiteGuidateRoutes, createAttivitaRoutes, createPacchettiRoutes, createNewsConventionRoutes, createOperatoreIncomingRoutes, createOperatoreOutdoorRoutes } = useSitemap()
const { $event } = useNuxtApp()
const { t, locale } = useI18n()
const directus = useDirectus()
const route = useRoute()

const { data: pagina, error: paginaError } = await useLazyAsyncData<any>(`page-${route.path}`, () => directus('/pages/content/by-url', { query: { url: route.path } }))
if (pagina.value && pagina.value.redirect) await navigateTo(pagina.value.redirect, { redirectCode: 301 })
if (paginaError.value) throw createError({ statusCode: paginaError.value.statusCode, statusMessage: paginaError.value.statusMessage })

const dataSottotitolo = computed(() => {
    if (pagina.value && pagina.value.eventi && pagina.value.eventi[0]) {
        const mixOrari = <Orario[]>[]
        if (pagina.value.eventi[0].usa_orari_sede === true && pagina.value.eventi[0].sede && pagina.value.eventi[0].sede.length > 0) {
            if (pagina.value.eventi[0].sede[0].risorse_id.orari && pagina.value.eventi[0].sede[0].risorse_id.orari.length > 0) mixOrari.push(...pagina.value.eventi[0].sede[0].risorse_id.orari)
            if (pagina.value.eventi[0].sede[0].risorse_id.orari_straordinari && pagina.value.eventi[0].sede[0].risorse_id.orari_straordinari.length > 0) mixOrari.push(...pagina.value.eventi[0].sede[0].risorse_id.orari_straordinari)
        } else mixOrari.push(...pagina.value.eventi[0].date)
        return verbalizzaSottotitolo(mixOrari, pagina.value.eventi[0].usa_orari_sede, pagina.value.eventi[0].data_inizio_orari_sede, pagina.value.eventi[0].data_fine_orari_sede)
    } else if (pagina.value && pagina.value.attivita && pagina.value.attivita[0] && pagina.value.attivita[0].date) {
        return verbalizzaSottotitolo(pagina.value.attivita[0].date as Orario[])
    } else if (pagina.value && pagina.value.pacchetti && pagina.value.pacchetti[0] && pagina.value.pacchetti[0].date) {
        return verbalizzaSottotitolo(pagina.value.pacchetti[0].date as Orario[])
    } else return null
})
const nomeOperatore = computed(() => {
    if (pagina.value && pagina.value.users && pagina.value.users[0]) {
        if (pagina.value.users[0].denominazione) return pagina.value.users[0].denominazione
        else return `${pagina.value.users[0].first_name} ${pagina.value.users[0].last_name}`
    } else return ''
})
const subinfoRicettivita = computed(() => {
    if (pagina.value && pagina.value.risorse && pagina.value.risorse[0] && (pagina.value.risorse[0].dati_non_pervenuti || pagina.value.risorse[0].chiusura_temporanea)) {
        const subinfo = []
        if (pagina.value.risorse[0].dati_non_pervenuti) subinfo.push(t('dati_non_pervenuti'))
        if (pagina.value.risorse[0].chiusura_temporanea) subinfo.push(t('chiusura_temporanea'))
        return subinfo.join(' | ')
    } else return null
})

const routes = computed(() => {
    if (pagina.value && pagina.value.eventi && pagina.value.eventi[0]) return createEventiRoutes(pagina.value.eventi[0], locale.value)
    else if (pagina.value && pagina.value.pagine && pagina.value.pagine[0]) return createPagineRoutes(pagina.value.pagine[0], locale.value)
    else if (pagina.value && pagina.value.prodotti && pagina.value.prodotti[0]) return createProdottiRoutes(pagina.value.prodotti[0], locale.value)
    else if (pagina.value && pagina.value.risorse && pagina.value.risorse[0]) return createRisorseRoutes(pagina.value.risorse[0], locale.value)
    else if (pagina.value && pagina.value.risorse_convention && pagina.value.risorse_convention[0]) return createRisorseConventionRoutes(pagina.value.risorse_convention[0], locale.value)
    else if (pagina.value && pagina.value.spettacoli && pagina.value.spettacoli[0]) return createSpettacoliRoutes(pagina.value.spettacoli[0], locale.value)
    else if (pagina.value && pagina.value.visite_guidate && pagina.value.visite_guidate[0]) return createVisiteGuidateRoutes(pagina.value.visite_guidate[0], locale.value)
    else if (pagina.value && pagina.value.attivita && pagina.value.attivita[0]) return createAttivitaRoutes(pagina.value.attivita[0], locale.value)
    else if (pagina.value && pagina.value.pacchetti && pagina.value.pacchetti[0]) return createPacchettiRoutes(pagina.value.pacchetti[0], locale.value)
    else if (pagina.value && pagina.value.news_convention && pagina.value.news_convention[0]) return createNewsConventionRoutes(pagina.value.news_convention[0], locale.value)
    else if (pagina.value && pagina.value.users && pagina.value.users[0] && pagina.value.users[0].pagina_operatore_outdoor) return createOperatoreOutdoorRoutes(pagina.value.users[0], locale.value)
    else if (pagina.value && pagina.value.users && pagina.value.users[0] && pagina.value.users[0].pagina_operatore_incoming) return createOperatoreIncomingRoutes(pagina.value.users[0], locale.value)
    else return null
})

const gotoTarget = (target: string | null | undefined) => {
    if (target) $event('goToAccordion', target)
}
</script>

<style lang="scss" scoped>
.breadcrumbPageCont {
    margin-bottom: var(--margin-default);
}

.titleBox {
    display: flex;
    flex-direction: column;
    gap: var(--margin-default);

    background-color: var(--color-background-box);
    max-width: 750px;
    border-radius: var(--size-header-radius);
    padding: var(--margin-default);
    box-sizing: border-box;

    h1 {
        text-wrap: balance;
    }

    .subinfo {
        text-transform: uppercase;
    }

    .categoria {
        text-transform: uppercase;
        color: var(--color-azure-dark);
    }

    .subcategorie {
        color: var(--color-azure-dark);
    }

    .data {
        font-weight: normal;
        text-transform: uppercase;
    }
}

.titleBoxButton,
.titleBoxInfo {
    background-color: var(--color-background-box);
    max-width: 750px;
    border-radius: var(--size-header-radius);
    padding: var(--margin-default);
    box-sizing: border-box;

    display: grid;
    grid-template-columns: 1fr min-content;
    gap: var(--margin-article);

    h1 {
        text-wrap: balance;
    }

    .boxText {
        display: flex;
        flex-direction: column;
        gap: var(--margin-default);

        .subinfo {
            text-transform: uppercase;

            .collab {
                text-transform: none;
                font-weight: 400;
            }
        }

        .categoria,
        .teatro {
            text-transform: uppercase;
            color: var(--color-azure-dark);
        }

        .edificio {
            font-weight: normal;
            color: var(--color-azure-dark);
        }
    }

    .boxButton {
        display: flex;
        align-items: center;
    }

    .boxInfo {
        .codicecir {
            text-transform: uppercase;
            white-space: nowrap;
            color: var(--color-text-gray);

            .codice {
                font-weight: normal;
            }
        }
    }
}

.titleTag {
    display: inline-block;
    font-weight: 800;
    text-transform: uppercase;

    padding: 9px 12px;
    border-radius: 8px;


    &.concluso {
        background-color: var(--color-background-gray-logo);
        color: white;
    }
}

.targetRow {
    margin: var(--margin-double) 0 var(--margin-article) 0;

    .cont {
        padding: 0 var(--margin-page);

        .targets {
            display: flex;
            flex-wrap: wrap;
            gap: var(--margin-gap);

            &:deep(.n-tag) {
                cursor: pointer;
            }
        }
    }
}

.body {
    margin: var(--margin-article) 0;

    .cont {
        padding: 0 var(--margin-page);
    }
}

@media screen and (max-width: 1240px) {
    main .cont {
        margin: 0;
    }

    main .cont.breadcrumbPageCont {
        margin: 0 var(--margin-default) var(--margin-mini) var(--margin-default);
    }

    .titleBox,
    .titleBoxButton,
    .titleBoxInfo {
        margin: 0 var(--margin-default);
    }

    .targetRow {
        margin: var(--margin-double) 0 var(--margin-double) 0;
    }
}

@media screen and (max-width: 600px) {

    .titleBoxButton,
    .titleBoxInfo {
        display: flex;
        flex-direction: column;
        gap: var(--margin-default);
    }
}
</style>