<template>
    <article v-if="risorsa">

        <section class="eventoInfo">
            <div class="cont">
                <div class="colonne">
                    <div class="innerCont colonnaMain">

                        <!-- BODY -->
                        <ModuloBodyMice v-if="risorsa.translations && risorsa.translations[0]?.body_mice" :body="risorsa.translations[0]?.body_mice" :anno_costruzione="risorsa.anno_costruzione" :anno_ristrutturazione="risorsa.anno_ristrutturazione" :anno_costituzione="risorsa.anno_costituzione" />

                        <!-- IMMAGINI -->
                        <ModuloImmagine v-if="risorsa.media_mice && risorsa.media_mice.length == 1" :image="risorsa.media_mice[0]" />
                        <ModuloMedia v-else-if="risorsa.media_mice && risorsa.media_mice.length > 1" :media="risorsa.media_mice" />

                        <!-- CONTATTI -->
                        <SezioneShare />
                        <LazySkipHydration>
                            <LazySezioneContatti :contatti="risorsa" />
                        </LazySkipHydration>

                        <SezioneAccordionRisorsaConvention :risorsa="risorsa" />

                    </div>
                </div>
            </div>
        </section>

        <!-- MAPPA -->
        <MappaCoordinate v-if="risorsa.geolocalizzazione && risorsa.geolocalizzazione.coordinates" :latitude="risorsa.geolocalizzazione.coordinates[1]" :longitude="risorsa.geolocalizzazione.coordinates[0]" />

    </article>
</template>

<script lang="ts" setup>
const { localeProperties } = useI18n()
const { createOpeningHours, getRisorsaMiceType } = useSchema()
const setI18nParams = useSetI18nParams()
const directusUrl = useDirectusUrl()
const route = useRoute()
const props = defineProps<{ risorsa: any, routes: any }>()

useHead({
    link: [{ rel: 'canonical', href: `https://turismotorino.org${route.path.replace(/\/$/, "")}` }],
    meta: [
        { name: 'city', content: () => props.risorsa && props.risorsa.comune ? props.risorsa.comune.nome : null },
    ]
});
useSeoMeta({
    title: () => props.risorsa && props.risorsa.translations && props.risorsa.translations[0]?.og_title ? `${props.risorsa.translations[0].og_title} | ${props.risorsa.tipologia_mice?.translations?.[0]?.etichetta} | Turismo Torino e Provincia` : null,
    ogTitle: () => props.risorsa && props.risorsa.translations && props.risorsa.translations[0]?.og_title ? `${props.risorsa.translations[0].og_title} | ${props.risorsa.tipologia_mice?.translations?.[0]?.etichetta} | Turismo Torino e Provincia` : null,
    twitterTitle: () => props.risorsa && props.risorsa.translations && props.risorsa.translations[0]?.og_title ? `${props.risorsa.translations[0].og_title} | ${props.risorsa.tipologia_mice?.translations?.[0]?.etichetta} | Turismo Torino e Provincia` : null,

    description: () => props.risorsa && props.risorsa.translations && props.risorsa.translations[0]?.og_description_mice ? props.risorsa.translations[0].og_description_mice : null,
    ogDescription: () => props.risorsa && props.risorsa.translations && props.risorsa.translations[0]?.og_description_mice ? props.risorsa.translations[0].og_description_mice : null,
    twitterDescription: () => props.risorsa && props.risorsa.translations && props.risorsa.translations[0]?.og_description_mice ? props.risorsa.translations[0].og_description_mice : null,

    ogImage: () => props.risorsa && props.risorsa.cover && props.risorsa.cover.height && props.risorsa.cover.width ? `${directusUrl}assets/${props.risorsa.cover.id}?width=1600&format=jpeg` : null,
    twitterImage: () => props.risorsa && props.risorsa.cover && props.risorsa.cover.height && props.risorsa.cover.width ? `${directusUrl}assets/${props.risorsa.cover.id}?width=1600&format=jpeg` : null,

    ogLocale: () => localeProperties.value.isold,
})

if (props.routes && route.name.indexOf('preview') != 0) setI18nParams(props.routes)

const itemListElement = computed(() => {
    const elements = <any[]>[]
    const parents = <{ slug: string, titolo: string }[]>[]
    const urls = <string[]>[]

    let hasMoreParents = true
    let currentParent = props.risorsa.tipologia_mice.pagina

    if (currentParent) {
        while (hasMoreParents) {
            if (currentParent.translations && currentParent.translations.length > 0) {
                const localeParentTranslation = currentParent.translations.find((t: any) => t.languages_code.code == localeProperties.value.code)
                if (localeParentTranslation && localeParentTranslation.slug) {
                    parents.push(localeParentTranslation)
                }
            }

            if (currentParent.parent) currentParent = currentParent.parent
            else hasMoreParents = false
        }
    }

    for (const p of parents.reverse()) {
        urls.push(p.slug)
        elements.push({
            '@type': 'ListItem',
            position: urls.length,
            name: p.titolo,
            item: `https://turismotorino.org/${urls.join('/')}`
        })
    }

    elements.push({
        '@type': 'ListItem',
        position: urls.length + 1,
        name: props.risorsa.translations?.[0]?.titolo,
        item: `https://turismotorino.org${route.path.replace(/\/$/, "")}`
    })

    return elements
})

useJsonld(() => ({
    '@context': 'https://schema.org',
    '@graph': [
        {
            '@type': 'BreadcrumbList',
            'itemListElement': itemListElement.value
        },
        {
            "@type": "WebPage",
            "@id": `https://turismotorino.org${route.path.replace(/\/$/, "")}/#webpage`,
            url: `https://turismotorino.org${route.path.replace(/\/$/, "")}`,
            name: props.risorsa && props.risorsa.translations && props.risorsa.translations[0]?.og_title ? props.risorsa.translations[0].og_title : 'Turismo Torino e Provincia',
            isPartOf: {
                "@id": `https://turismotorino.org/${localeProperties.value.code}/#website`,
            },
            primaryImageOfPage: props.risorsa && props.risorsa.cover && props.risorsa.cover.height && props.risorsa.cover.width ? {
                '@type': 'ImageObject',
                '@id': `https://turismotorino.org${route.path.replace(/\/$/, "")}/#primaryimage`,
                url: `${directusUrl}assets/${props.risorsa.cover.id}?width=1600&format=jpeg`,
                width: 1600,
                height: (props.risorsa.cover.height * 1600 / props.risorsa.cover.width).toFixed(0),
                inLanguage: localeProperties.value.isold,
                caption: props.risorsa.cover.title
            } : undefined,
            datePublished: props.risorsa && props.risorsa.date_created ? new Date(props.risorsa.date_created).toJSON() : undefined,
            dateModified: props.risorsa && props.risorsa.date_updated ? new Date(props.risorsa.date_updated).toJSON() : undefined,
            description: props.risorsa && props.risorsa.translations && props.risorsa.translations[0]?.og_description_mice ? props.risorsa.translations[0].og_description_mice : undefined,
            inLanguage: localeProperties.value.isold,
            potentialAction: [
                {
                    "@type": "ReadAction",
                    target: [
                        `https://turismotorino.org${route.path.replace(/\/$/, "")}`
                    ]
                }
            ]
        },
        {
            '@type': getRisorsaMiceType(props.risorsa),
            '@id': `https://turismotorino.org${route.path.replace(/\/$/, "")}/#localbusiness`,
            name: props.risorsa.translations && props.risorsa.translations[0]?.titolo ? props.risorsa.translations[0].titolo : undefined,
            image: props.risorsa.media_mice ? props.risorsa.media_mice.map((m: any) => `${directusUrl}assets/${m.directus_files_id.id}?width=1600&format=jpeg`) : undefined,
            url: props.risorsa.link_esterni && props.risorsa.link_esterni.filter((l: any) => l.tipologia == 'website').length > 0 ? props.risorsa.link_esterni.filter((l: any) => l.tipologia == 'website')[0].link : undefined,
            email: props.risorsa.contatti_email && props.risorsa.contatti_email.length > 0 ? props.risorsa.contatti_email[0].email : undefined,
            address: props.risorsa.indirizzo ? {
                '@type': 'PostalAddress',
                addressCountry: "IT",
                addressLocality: props.risorsa.comune ? props.risorsa.comune.nome : undefined,
                //addressRegion: "",
                postalCode: props.risorsa.cap,
                streetAddress: props.risorsa.indirizzo
            } : undefined,
            geo: props.risorsa.geolocalizzazione && props.risorsa.geolocalizzazione.coordinates ? {
                '@type': 'GeoCoordinates',
                latitude: props.risorsa.geolocalizzazione.coordinates[1],
                longitude: props.risorsa.geolocalizzazione.coordinates[0]
            } : undefined,
            openingHoursSpecification: createOpeningHours(props.risorsa.orari),
            telephone: props.risorsa.contatti_telefono && props.risorsa.contatti_telefono.length > 0 ? props.risorsa.contatti_telefono[0].numero : undefined,
            description: props.risorsa.translations && props.risorsa.translations[0]?.og_description_mice ? props.risorsa.translations[0].og_description_mice : undefined,
            sameAs: props.risorsa.link_esterni ? props.risorsa.link_esterni.filter((l: any) => l.tipologia != 'acquista' && l.tipologia != 'prenota').map((l: any) => l.link) : undefined
        }
    ]
}))
</script>

<style lang="scss" scoped>
article {
    margin: var(--article-top) 0 var(--article-bottom) 0;
    display: flex;
    flex-direction: column;
    gap: var(--article-gap-outer);

    .eventoInfo {
        .colonne {
            display: flex;
            justify-content: space-between;
            gap: var(--margin-double);

            .colonnaMain {
                display: flex;
                flex-direction: column;
                gap: var(--article-gap-outer);
                width: 100%;
            }

            &:deep(.cont) {
                padding: 0;
            }
        }
    }
}

@media screen and (max-width: 1060px) {
    article {
        .eventoInfo {
            .colonne {
                flex-direction: column;
            }
        }
    }
}
</style>