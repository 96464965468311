<template>
    <article v-if="spettacolo">

        <section class="spettacoloInfo">
            <div class="cont">
                <div class="colonne">
                    <div class="innerCont colonnaMain">

                        <!-- BODY -->
                        <ModuloBody v-if="spettacolo.translations && spettacolo.translations[0]?.descrizione" :body="spettacolo.translations[0]?.descrizione" />

                        <!-- IMMAGINI -->
                        <ModuloImmagine v-if="spettacolo.cover" :image="{ directus_files_id: spettacolo.cover }" :armonia="true" />

                        <!-- CONTATTI -->
                        <SezioneShare />
                        <LazySkipHydration>
                            <LazySezioneContattiSpettacolo :spettacolo="spettacolo" />
                        </LazySkipHydration>

                        <!-- ORARI / TARIFFE -->
                        <SezioneCardRisorsa :risorsa="spettacolo" />
                        <SezioneAccordionSpettacolo :spettacolo="spettacolo" />

                        <!-- MACROEVENTI / SOTTOEVENTI -->
                        <ListaMacroeventi v-if="spettacolo.inserito_in && spettacolo.inserito_in.length > 0" :macroeventi="spettacolo.inserito_in" />

                    </div>
                    <aside>

                        <!-- PRODOTTI -->
                        <ListaSideProdotti v-if="spettacolo.aderisce_a && spettacolo.aderisce_a.length > 0" :prodotti="spettacolo.aderisce_a" />

                    </aside>
                </div>
            </div>
        </section>

        <!-- MAPPA -->
        <MappaCoordinate v-if="spettacolo.luogo && spettacolo.luogo.geolocalizzazione && spettacolo.luogo.geolocalizzazione.coordinates" :latitude="spettacolo.luogo.geolocalizzazione.coordinates[1]" :longitude="spettacolo.luogo.geolocalizzazione.coordinates[0]" />

        <!-- SEDE -->
        <ListaSedi v-if="spettacolo.luogo && spettacolo.luogo.risorsa" :sedi="[{ risorse_id: spettacolo.luogo.risorsa }]" />


    </article>
</template>

<script lang="ts" setup>
const { localeProperties, t } = useI18n()
const { dizionario } = useSitemap()
const setI18nParams = useSetI18nParams()
const directusUrl = useDirectusUrl()
const route = useRoute()
const props = defineProps<{ spettacolo: any , routes: any}>()

useHead({
    link: [{ rel: 'canonical', href: `https://turismotorino.org${route.path.replace(/\/$/, "")}` }]
});
useSeoMeta({
    title: () => props.spettacolo && props.spettacolo.translations && props.spettacolo.translations[0]?.og_title ? `${props.spettacolo.translations[0].og_title} | ${t('eventi')} | Turismo Torino e Provincia` : null,
    ogTitle: () => props.spettacolo && props.spettacolo.translations && props.spettacolo.translations[0]?.og_title ? `${props.spettacolo.translations[0].og_title} | ${t('eventi')} | Turismo Torino e Provincia` : null,
    twitterTitle: () => props.spettacolo && props.spettacolo.translations && props.spettacolo.translations[0]?.og_title ? `${props.spettacolo.translations[0].og_title} | ${t('eventi')} | Turismo Torino e Provincia` : null,

    description: () => props.spettacolo && props.spettacolo.translations && props.spettacolo.translations[0]?.og_description ? props.spettacolo.translations[0].og_description : null,
    ogDescription: () => props.spettacolo && props.spettacolo.translations && props.spettacolo.translations[0]?.og_description ? props.spettacolo.translations[0].og_description : null,
    twitterDescription: () => props.spettacolo && props.spettacolo.translations && props.spettacolo.translations[0]?.og_description ? props.spettacolo.translations[0].og_description : null,

    ogImage: () => props.spettacolo && props.spettacolo.cover && props.spettacolo.cover.height && props.spettacolo.cover.width ? `${directusUrl}assets/${props.spettacolo.cover.id}?width=1600&format=jpeg` : null,
    twitterImage: () => props.spettacolo && props.spettacolo.cover && props.spettacolo.cover.height && props.spettacolo.cover.width ? `${directusUrl}assets/${props.spettacolo.cover.id}?width=1600&format=jpeg` : null,

    ogLocale: () => localeProperties.value.isold,
})

if (props.routes && route.name.indexOf('preview') != 0) setI18nParams(props.routes)

useJsonld(() => ({
    '@context': 'https://schema.org',
    '@graph': [
        {
            '@type': 'BreadcrumbList',
            itemListElement: [
                {
                    '@type': 'ListItem',
                    position: 1,
                    name: dizionario.titoloVisita[localeProperties.value.code as 'it' | 'en' | 'fr'],
                    item: `https://turismotorino.org/${dizionario.linkVisita[localeProperties.value.code as 'it' | 'en' | 'fr']}`
                },
                {
                    '@type': 'ListItem',
                    position: 2,
                    name: dizionario.titoloEventi[localeProperties.value.code as 'it' | 'en' | 'fr'],
                    item: `https://turismotorino.org/${dizionario.linkVisita[localeProperties.value.code as 'it' | 'en' | 'fr']}/${dizionario.linkEventi[localeProperties.value.code as 'it' | 'en' | 'fr']}`
                },
                {
                    '@type': 'ListItem',
                    position: 3,
                    name: dizionario.titoloMusica[localeProperties.value.code as 'it' | 'en' | 'fr'],
                    item: `https://turismotorino.org/${dizionario.linkVisita[localeProperties.value.code as 'it' | 'en' | 'fr']}/${dizionario.linkEventi[localeProperties.value.code as 'it' | 'en' | 'fr']}/${dizionario.linkMusica[localeProperties.value.code as 'it' | 'en' | 'fr']}`
                },
                {
                    '@type': 'ListItem',
                    position: 4,
                    name: props.spettacolo.translations[0].titolo,
                    item: `https://turismotorino.org${route.path.replace(/\/$/, "")}`
                }
            ]
        },
        {
            "@type": "WebPage",
            "@id": `https://turismotorino.org${route.path.replace(/\/$/, "")}/#webpage`,
            url: `https://turismotorino.org${route.path.replace(/\/$/, "")}`,
            name: props.spettacolo && props.spettacolo.translations && props.spettacolo.translations[0]?.og_title ? props.spettacolo.translations[0].og_title : undefined,
            isPartOf: {
                "@id": `https://turismotorino.org/${localeProperties.value.code}/#website`,
            },
            primaryImageOfPage: props.spettacolo && props.spettacolo.cover && props.spettacolo.cover.height && props.spettacolo.cover.width ? {
                '@type': 'ImageObject',
                '@id': `https://turismotorino.org${route.path.replace(/\/$/, "")}/#primaryimage`,
                url: `${directusUrl}assets/${props.spettacolo.cover.id}?width=1600&format=jpeg`,
                width: 1600,
                height: (props.spettacolo.cover.height * 1600 / props.spettacolo.cover.width).toFixed(0),
                inLanguage: localeProperties.value.isold,
                caption: props.spettacolo.cover.title
            } : undefined,
            datePublished: props.spettacolo && props.spettacolo.date_created ? new Date(props.spettacolo.date_created).toJSON() : undefined,
            dateModified: props.spettacolo && props.spettacolo.date_updated ? new Date(props.spettacolo.date_updated).toJSON() : undefined,
            description: props.spettacolo && props.spettacolo.translations && props.spettacolo.translations[0]?.og_description ? props.spettacolo.translations[0].og_description : undefined,
            inLanguage: localeProperties.value.isold,
            potentialAction: [
                {
                    "@type": "ReadAction",
                    target: [
                        `https://turismotorino.org/${localeProperties.value.code}`
                    ]
                }
            ]
        },
        {
            '@type': 'MusicEvent',
            '@id': `https://turismotorino.org${route.path.replace(/\/$/, "")}/#event`,
            name: props.spettacolo && props.spettacolo.translations && props.spettacolo.translations[0]?.og_title ? props.spettacolo.translations[0].og_title : undefined,
            description: props.spettacolo && props.spettacolo.translations && props.spettacolo.translations[0]?.og_description ? props.spettacolo.translations[0].og_description : undefined,
            image: props.spettacolo.media ? props.spettacolo.media.map((m: any) => `${directusUrl}assets/${m.directus_files_id.id}?width=1600&format=jpeg`) : undefined,
            url: props.spettacolo.link_esterni && props.spettacolo.link_esterni.filter((l: any) => l.tipologia == 'website').length > 0 ? props.spettacolo.link_esterni.filter((l: any) => l.tipologia == 'website')[0].link : undefined,
            eventStatus: "https://schema.org/EventScheduled",
            eventAttendanceMode: "https://schema.org/OfflineEventAttendanceMode",
            isAccessibleForFree: false,
            location: props.spettacolo.luogo && props.spettacolo.luogo.indirizzo ? {
                "@type": "Place",
                name: props.spettacolo.luogo.nome,
                address: {
                    "@type": "PostalAddress",
                    streetAddress: props.spettacolo.luogo.indirizzo,
                    addressLocality: props.spettacolo.luogo.comune ? props.spettacolo.luogo.comune.nome : undefined,
                    postalCode: props.spettacolo.luogo.cap,
                    addressCountry: "IT"
                }
            } : undefined,
            offers: props.spettacolo.link_esterni && props.spettacolo.link_esterni.filter((l: any) => l.tipologia == 'acquista' || l.tipologia == 'prenota').length > 0 ?
                props.spettacolo.link_esterni.filter((l: any) => l.tipologia == 'acquista' || l.tipologia == 'prenota').map((l: any) => ({
                    '@type': 'Offer',
                    url: l.link,
                })) : undefined,
            sameAs: props.spettacolo.link_esterni ? props.spettacolo.link_esterni.filter((l: any) => l.tipologia != 'acquista' && l.tipologia != 'prenota').map((l: any) => l.link) : undefined,
            startDate: props.spettacolo.date_spettacoli && props.spettacolo.date_spettacoli.length > 0 ? props.spettacolo.date_spettacoli.sort((a: any, b: any) => a.data.localeCompare(b.data))[0].data : undefined,
            endDate: props.spettacolo.date_spettacoli && props.spettacolo.date_spettacoli.length > 0 ? props.spettacolo.date_spettacoli.sort((a: any, b: any) => a.data.localeCompare(b.data))[props.spettacolo.date_spettacoli.length - 1].data : undefined
        }
    ]
}))
</script>

<style lang="scss" scoped>
article {
    margin: var(--article-top) 0 var(--article-bottom) 0;
    display: flex;
    flex-direction: column;
    gap: var(--article-gap-outer);

    .spettacoloInfo {
        .colonne {
            display: flex;
            justify-content: space-between;
            gap: var(--margin-double);

            .colonnaMain {
                display: flex;
                flex-direction: column;
                gap: var(--article-gap-outer);
                width: 100%;
            }

            &:deep(.cont) {
                padding: 0;
            }
        }
    }
}

@media screen and (max-width: 1060px) {
    article {
        .spettacoloInfo {
            .colonne {
                flex-direction: column;
            }
        }
    }
}
</style>